@import '../../Styles/settings';

.root {
  font-weight: 300;
  display: flex;
  align-items: baseline;
}

.hasPadding {
  --padding-bottom: 24px;

  @media #{$md-up} {
    --padding-bottom: 48px;
  }

  padding-bottom: var(--padding-bottom);
}
