.circle-icon {
  width: 32px;
  height: 32px;
  background-color: #eef2fd;
  border-radius: 50%;
  padding: 8px;
  overflow: visible;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &--lg {
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
