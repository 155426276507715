@import '../../../Styles/settings';

.priceInfoWrapper {
  padding: 0;
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
}

.addToCartWrapper {
  width: 100%;
}

.cta-section {
  --trash-icon-order: 1;
  --note-icon-order: 2;
  --quantity-wrapper-order: 3;
  --cta-section-justify: space-between;
  --note-flex-width: auto;
  --cta-section-margin-top: 16px;

  @media #{$md-up} {
    --note-icon-order: 1;
    --quantity-wrapper-order: 2;
    --trash-icon-order: 3;
    --cta-section-justify: flex-end;
    --note-flex-width: 100%;
    --cta-section-margin-top: 0;
  }

  display: flex;
  justify-content: var(--cta-section-justify);
  align-items: center;
  margin-top: var(--cta-section-margin-top);
  gap: 16px;

  &__remove {
    background: transparent;
    border: 1px solid transparent;
  }

  &__remove {
    order: var(--trash-icon-order);
    padding: 0;
    cursor: pointer;

    svg {
      stroke: $near-black;
    }
  }

  &__quantity-wrapper {
    order: var(--quantity-wrapper-order);
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__price {
    font-size: 16px;
    @include HelveticaMedium;
    white-space: nowrap;
  }

  &__loading {
    white-space: nowrap;
    order: 4;
  }
}

@media #{$md-up} {
  .priceInfoWrapper {
    flex: 1;
    @include styleComponent('style_id_productCardBtn_addToCart') {
      margin-left: 1px;
    }
  }
}

@include media(600px) {
  // .addToCartWrapper {
  //   width: 200px;
  //   margin-left: auto;
  // }
}

@media print {
  .addToCartWrapper {
    display: none;
  }
}
