@import '../../Styles/settings';

.root {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.inputContainer {
	display: flex;
	flex-direction: row;
	gap: 12px;

	input[disabled] {
		background-color: transparent;
	}
}

.inputWithIcon {
	input {
		padding-left: 44px;
	}
}

.inputApplied {
	border: 1px solid $bona-border-grey;
	border-radius: 4px;

	input,
	input:hover,
	button {
		border: none !important;
	}
}

.inputError {
	input {
		@include input-has-error;
		&:focus {
			border: 1px solid $color-error;
		}

		+ label {
			color: $color-error !important;
		}
	}
}

.success {
	font-weight: 400;
	font-size: 12px;
	line-height: 133%;
	margin-bottom: 0;
}

.error {
	font-weight: 400;
	font-size: 12px;
	line-height: 133%;
	color: $error;
	margin-bottom: 0;
}

.inputLeftIcon {
	left: 16px;
	top: 14px;
}

.promoButton {
	height: 48px;
}
