@import '../../../Styles/settings';

.root {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px;
}

.cartB2bTotal {
  margin: 0;
  padding: 20px 32px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $bona-light-grey;

  @media #{$sm-down} {
    display: flex;
    flex-direction: column;
  }
}

.promoWrapper {
  width: 339px;

  @media #{$sm-down} {
    width: 100%;
  }
}

.totalWrapper {
  display: flex;
  justify-content: space-between;

  @media #{$sm-down} {
    width: 100%;
  }
}

.orderInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.totalB2BPromo {
  font-size: 14px;
  line-height: 129%;
  color: $light-blue;
}

.totalB2bPrice {
  font-size: 18px;
  line-height: 133%;
  @include HelveticaMedium;
}

.cart-total {
  --justify: space-between;
  --padding: 40px 16px 24px 16px;

  @media #{$md-up} {
    --justify: center;
    --padding: 48px;
  }

  padding: var(--padding);
  display: flex;
  align-items: baseline;
  justify-content: var(--justify);
  margin: 0;
  border-top: 1px solid $bona-light-grey;

  &__price {
    font-size: 18px;
    @include HelveticaMedium;

    span {
      margin-left: 8px;
    }
  }
}

.bold {
  font-weight: bold;
}

.slideDownOuter {
  height: 0;
  overflow: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  display: block;
}

.slideDownExpanded {
  height: auto;
}

@media #{$sm-down} {
  .root {
    gap: 36px;
    padding: 16px;
  }
}
