@import '../../../Styles/settings';

.checkout-section-title {
  --title-size: 20px;
  --margin-left: 8px;

  @media #{$md-up} {
    --title-size: 24px;
    --margin-left: 16px;
  }

  display: inline-flex;
  align-items: center;

  &--hide-icon &__heading {
    margin-left: 0 !important;
  }

  &__heading {
    font-size: var(--title-size);
    margin-bottom: 0;
    line-height: 24px;
    margin-left: var(--margin-left);
  }
}
