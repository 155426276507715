@import '../../../Styles/settings';

.cart-top {
  --top-padding: 24px 16px 32px 16px;
  --border: 1px solid #{$bona-light-grey};

  @media #{$md-up} {
    --top-padding: 48px;
    --border: none;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--top-padding);
  border-bottom: 1px solid $bona-light-grey;

  &__article-count {
    display: inline-flex;
    align-items: center;
  }
}

.h1 {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 24px;
  margin-left: 16px;
}

.emptyCartMessage {
  flex: 1 0 100%;
  padding: 8px;

  margin: 24px 0 8px;
  // global selector in order to remove ugly margin for the editorial content
  p:last-of-type {
    margin-bottom: 0;
  }
}

@media #{$md-up} {
  .emptyCartMessage {
    padding: 8px;
  }
  .h1 {
    font-size: 24px;
  }
}
