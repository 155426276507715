@import '../../Styles/settings';

.root {
  max-width: 960px;
  margin: 0 auto 35px;
  background-color: white;
  padding: 24px 16px;
  margin-bottom: 35px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  height: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.noMaxWidth {
    max-width: 100%;
  }
  &.noPadding {
    padding: 0;
  }
}

@include media(500px) {
  .root {
    padding: 20px 15px;
  }
}

@media #{$md-up} {
  .root {
    padding: 48px;
  }
}
