@import '../../../Styles/settings';

.clearCartBtn {
  --flex-direction: row;
  --label-size: 12px;

  @media #{$md-up} {
    --flex-direction: row-reverse;
    --label-size: 16px;
  }

  border: none;
  padding: 0;
  background: transparent;
  font-size: var(--label-size);
  @include HelveticaMedium;
  cursor: pointer;
  position: relative;
  color: $color-brand;
  display: flex;
  align-items: center;
  flex-direction: var(--flex-direction);
  gap: 8px;
}

.trashcanIcon {
  stroke: $color-brand;
  vertical-align: bottom;
}
