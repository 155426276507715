@import '../../Styles/settings';

.root {
  --padding: 16px;
  --spacing-span: 4px;
  --link-size: 14px;

  @media #{$md-up} {
    --padding: 24px;
    --spacing-span: 8px;
    --link-size: 16px;
  }

  border-radius: 8px;
  padding: var(--padding);
  font-weight: 300;
  font-style: normal;
  width: 100%;
  flex: 0 0 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 12px;
  *:not(.root) + &,
  &:first-child {
    margin-top: 0;
  }

  &--gray {
    background-color: $near-white;
  }

  &--lightest-blue {
    background-color: $lightest-blue;
  }

  h4 {
    margin-bottom: 8px;
    line-height: 20px;
  }

  span {
    display: block;
    line-height: 20px;
    margin-bottom: var(--spacing-span);
    color: $darkest-gray;
  }

  a {
    color: $color-brand;
    font-size: var(--link-size);
    @include HelveticaMedium;
    text-decoration: none;
  }

  &__icon {
    position: absolute;
    top: var(--padding);
    right: var(--padding);

    svg {
      stroke: $color-brand;
    }
  }
}

@media #{$md-up} {
  .root {
    margin-bottom: 0;
  }   
}
