@import '../../../Styles/settings';

.root {
  display: flex;
  width: 100%;
  position: relative;
}

.wrapper {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  background: white;
  &.noBgListMode {
    background: transparent;
  }
}

.root {
  &.noBgListMode {
    z-index: map-get($zIndexes, 'productCard');
  }
  &.disabled {
    opacity: 1;
  }
}
