@import '../../../Styles/settings';

.root {
  @include HelveticaMedium;
  color: $color-brand;
  font-size: 14px;
  background: transparent;
  border: 1px solid transparent;
  padding-top: 16px;
  white-space: nowrap;
  order: var(--note-icon-order);
  flex-basis: var(--note-flex-width);
  cursor: pointer;

  svg {
    stroke: $color-brand;
    vertical-align: text-top;
    margin-right: 8px;
  }
}

@media #{$sm-down} {
  .root {
    padding-top: 0;
  }
}
