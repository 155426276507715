@import '../../../Styles/settings';

.root {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-weight: 300;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
}

.label {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  cursor: pointer;
  color: $darkest-gray;

  a {
    color: $color-brand;
  }

  p {
    margin-bottom: 0;
  }
}

.input {
  opacity: 0;
  position: absolute;

  &:focus + .square {
    box-shadow: 0px 0px 0px 1px $color-brand;
    border-color: $color-brand;
  }

  &:checked + .square {
    &:after {
      transform: scale(1);
    }
    & + .border {
      border-color: $color-brand;
      background-color: $color-brand;
    }
  }
}

.text {
  z-index: 1;
  padding-left: 5px;
  width: calc(100% - 18px);
}

.square {
  flex: 0 0 18px;
  height: 18px;
  width: 18px;
  border-radius: 1px;
  border: solid 1px $bona-dark-grey;
  pointer-events: none;
  transition: box-shadow 0.3s $main-timing-function;
  position: relative;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: $color-brand;
    left: 2px;
    top: 2px;
    transform: scale(0);
    transition: transform 0.3s $main-timing-function;
  }

  &.invalid {
    border: 1px solid $color-error;
  }
}

.input,
.topContent,
.square,
.label {
  z-index: 1;
}

@media #{$md-up} {
  .root {
    &:hover {
      .square:not(.invalid) {
        box-shadow: 0px 0px 0px 1px $color-brand;
      }
    }
  }

  .input:checked + .square {
    box-shadow: 0px 0px 0px 0px $color-brand;
    border-color: $color-brand;
  }

  .text {
    padding-left: 10px;
  }
}
